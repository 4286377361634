import React from 'react'
import { Link } from 'gatsby'

import styled from '@emotion/styled'
import { Flex } from '../vendor/voidkit/ui'

import Layout from '../components/layout'
import SEO from '../components/seo'

export const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <PureIndexPage />
  </Layout>
)

const CTA = styled(Flex)`
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: #344955;
  border-radius: 4px;
  min-width: 128px;
  min-height: 32px;
  padding: 16px;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`

CTA.defaultProps = {
  as: Link,
}

export const PureIndexPage = () => (
  <CTA id="button-enter" to="/board">
    Enter
  </CTA>
)

export default IndexPage
